export const SaveMsg = `
    #graphql
    mutation contact_form($data: create_contact_form_input!) {
        create_contact_form_item(data: $data) {
          id
        }
    }
`;

export const GetContactInfoById = `
  #graphql
  query GetContactInfoById($id: ID!) {
    contact_form_by_id(id: $id) {
      id,
      name,
      email,
      message
      site_id {
			domain_name
		}
    }
  }
`;
