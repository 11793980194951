const getExceptionMessage = (exception) => {
  // If the exception is axios exceptioon then try getting the message
  // From the body
  const isAxiosException =
    exception &&
    exception.response &&
    exception.response.data &&
    exception.response.data.message;

  return isAxiosException
    ? exception.response.data.message
    : exception.message
      ? exception.message
      : exception;
};

export default getExceptionMessage;
