import setData from '../../setData';
import fetchData from '../../fetchData';
import { SaveMsg, GetContactInfoById } from './queries';

export const saveenquiry = async (variables = {}) => {
  const data = await setData(SaveMsg, { data: variables });

  return data;
};


export const getContactInfoById = async (id) => {
  const data = await fetchData(GetContactInfoById, {
    variables: {
      id: id,
    },
  });
  return data.data.contact_form_by_id;
};