import fetchData from '../../fetchData';
import {
  GetLines,
  GetShapes,
  GetCategoryCount,
  GetSources,
  GetPaginatedIcons,
  GetSingleIcon,
  GetPaginatedCliparts,
  GetSingleClipart,
  GetSingleShape,
} from './queries';

export const getLines = async (variables = {}) => {
  const data = await fetchData(GetLines, {
    variables: {},
  });

  return data.data;
};

export const getShapes = async (variables = { limit: 500, page: 1 }) => {
  const data = await fetchData(GetShapes, {
    variables,
  });

  return data.data;
};

export const getCategoryCount = async (variables = {}) => {
  const data = await fetchData(GetCategoryCount, {
    variables,
  });

  return data.data;
};

export const getSources = async (variables = {}) => {
  const data = await fetchData(GetSources, {
    variables: {},
  });

  return data.data;
};

export const getPaginatedIcons = async (variables = { limit: 5, page: 5 }) => {
  const data = await fetchData(GetPaginatedIcons, {
    variables,
  });

  return data.data;
};

export const getSingleIcon = async (variables) => {
  const data = await fetchData(GetSingleIcon, {
    variables,
  });

  return data.data;
};

export const getPaginatedCliparts = async (
  variables = { limit: 5, page: 5 },
) => {
  const data = await fetchData(GetPaginatedCliparts, {
    variables,
  });

  return data.data;
};

export const getSingleClipart = async (variables) => {
  const data = await fetchData(GetSingleClipart, {
    variables,
  });

  return data.data;
};

export const getSingleShapeByID = async (variables) => {
  const data = await fetchData(GetSingleShape, {
    variables,
  });

  return data.data;
};
