export const DOSPACE_ENDPOINT = process.env.DOSPACE_ENDPOINT;
export const PRODUCTS_PER_PAGE = 30;
export const MAX_FILE_SIZE = process.env.MAX_FILE_SIZE ?? 5;
export const API_SECRET = process.env.API_SECRET;
export const FLOW_API_KEY = process.env.FLOW_API_KEY;
export const TOS_NOTIFY_EMAILS = process.env.TOS_NOTIFY_EMAILS;
export const PIXABAY_API_KEY = process.env.PIXABAY_API_KEY;
export const TYPESENSE_API_KEY = process.env.TYPESENSE_API_KEY;
export const TYPESENSE_CLUSTER_NAME = process.env.TYPESENSE_CLUSTER_NAME;
export const OPENAI_API_KEY = process.env.OPENAI_API_KEY;

export const DOSPACE_UPLOADFOLDERNAME = process.env.DOSPACE_UPLOADFOLDERNAME;
export const DOSPACE_AUTOFILLLOGOFOLDERNAME =
  process.env.DOSPACE_AUTOFILLLOGOFOLDERNAME;
export const DOSPACE_SAVEDFOLDERNAME = process.env.DOSPACE_SAVEDFOLDERNAME;
export const DOSPACE_USERFILEUPLOADFOLDERNAME =
  process.env.DOSPACE_USERFILEUPLOADFOLDERNAME;
export const DOSPACE_USERVIDEOUPLOADFOLDERNAME =
  process.env.DOSPACE_USERVIDEOUPLOADFOLDERNAME;
export const DOSPACE_USERAUDIOUPLOADFOLDERNAME =
  process.env.DOSPACE_USERAUDIOUPLOADFOLDERNAME;
export const DOSPACE_ORDERSFOLDERNAME = process.env.DOSPACE_ORDERSFOLDERNAME;
export const DOSPACE_USER_FILES_FOLDER = process.env.DOSPACE_USER_FILES_FOLDER;

export const DIRECTUS_ICON_UPLOAD_FOLDER =
  '51644ccf-a621-4430-ba15-740b4a2702d4';
export const DIRECTUS_PHOTOS_UPLOAD_FOLDER =
  '27b4d1fc-362c-4fe8-be8a-4d890c596b69';
export const DIRECTUS_CLIPART_UPLOAD_FOLDER =
  '404be1f9-5679-44f5-92e1-007e8d9329ff';
export const DIRECTUS_SHAPES_UPLOAD_FOLDER =
  '894ac1f0-99c2-42c8-91a8-04bc8b522e1e';
