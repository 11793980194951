import ApiService from './ApiService';
import { signIn } from 'next-auth/react';

class User {
  static findUserByEmail = async (email, siteId) => {
    try {
      const parsedEmail = email.trim().toLowerCase();
      const parsedSiteId = siteId;

      const { data } = await ApiService.instance.post(
        '/api/datasource/users/finduserbyemail',
        { email: parsedEmail, siteId: parsedSiteId },
      );

      return Promise.resolve(data.user.length ? data.user[0] : null);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static createNewUserWithCredentials = async (newUser) => {
    try {
      const { data } = await ApiService.instance.post(
        '/api/datasource/users/createuser',
        {
          ...newUser,
          role: {
            name: 'customer',
            id: process.env.NEXT_PUBLIC_CUSTOMER_ROLE,
          },
        },
      );
      if (!data.user)
        throw new Error(
          'Customer with this email already exists. Choose a different email',
        );
      return Promise.resolve(data.user);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static loginUser = async (email, password, siteId, custom, loginData) => {
    const res = await signIn('credentials', {
      redirect: false,
      email: email,
      password: password,
      siteid: siteId,
      custom: custom,
    });

    if (res?.error) {
      throw new Error(res.error);
    }

    if (loginData) window.location.replace(loginData.callbackUrl);

    return res;
  };

  static updateUserInformation = async (userId, newInfo) => {
    try {
      const { data } = await ApiService.instance.post(
        '/api/datasource/users/updateuser',
        { userId, newInfo },
      );
      if (!data.user)
        throw new Error('Could Not Update Details. Please Try Again');
      return Promise.resolve(data.user);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static checkUserPassword = async (email, password, newPass, siteId) => {
    try {
      const { data } = await ApiService.instance.post(
        '/api/datasource/users/checkpassword',
        { email, password, newPass, siteId },
      );

      return Promise.resolve(data ? true : false);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static requestPassword = async (id, siteUrl) => {
    try {
      const { data } = await ApiService.instance.post(
        '/api/datasource/users/requestpassword',
        { id, siteUrl },
      );

      return Promise.resolve(data);
    } catch (e) {
      Promise.reject(e);
    }
  };

  static resetPassword = async (token, password) => {
    try {
      const { data } = await ApiService.instance.post(
        '/api/datasource/users/resetpassword',
        { token, password },
      );
      return Promise.resolve(data);
    } catch (e) {
      Promise.reject(e);
    }
  };

  static sendVerificationEmail = async (id, email, siteName, siteUrl) => {
    try {
      const { data } = await ApiService.instance.post(
        '/api/datasource/users/sendVerificationEmail',
        { userId: id, siteName, siteUrl, email },
      );

      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static verifyToken = async (token, siteId) => {
    if (!token) {
      return;
    }
    try {
      const { data } = await ApiService.instance.post(
        '/api/datasource/users/verifyUserToken',
        {
          siteId,
          token,
        },
      );

      return Promise.resolve(data);
    } catch (error) {
      console.error('Invalid or expired token', error);
      return {
        success: false,
        message: error?.response?.data?.error || error.message,
      };
    }
  };

  static addUserViolation = async (body) => {
    const { data } = await ApiService.instance.post(
      '/api/add_user_violation',
      body,
    );

    return data;
  };

  static checkUserSubscriptionStatus = async () => {
    const { data } = await ApiService.instance.get(
      '/api/stripe/checkActiveSubscription',
    );
    return data;
  };
}

export default User;
