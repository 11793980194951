import Head from 'next/head';
import Router from 'next/router';
import { useEffect } from 'react';
import App from 'next/app';
import { SessionProvider } from 'next-auth/react';
import NProgress from 'nprogress';
import { LimitSavedDesignProvider } from 'context/LimitSavedDesignContext';
import { AuthProvider } from 'context/AuthContext';
import { ToastContainer } from 'react-toastify';
import { SubscriptionProvider } from 'context/SubscriptionContext';
import { SiteInfoProvider } from '@/context/SiteInfoContext';
import { CategoryProvider } from '@/context/CategoryContext';
import { FilterProvider } from '@/context/FilterContext';
import { API_SECRET } from '@/constants/privateConstants';
import datasource from '../datalayer';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/font-awesome.min.css';
import '../styles/fonts.css';
import '../styles/sass/styles.scss';

function MyApp({ Component, pageProps, session }) {
  const getLayout = Component.getLayout || ((props, page) => page);
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
  }, []);
  NProgress.configure({ showSpinner: false });

  useEffect(() => {
    Router.events.on('routeChangeStart', (url) => {
      NProgress.start();
    });

    Router.events.on('routeChangeComplete', (url) => {
      NProgress.done(false);
    });

    Router.events.on('routeChangeError', (url) => {
      NProgress.done(false);
    });
  }, []);

  return (
    <>
      <Head>
        <meta name="robots" content="index" />
        <meta name="googlebot" content="index" />
      </Head>
      <ToastContainer
        position={'top-center'}
        closeOnClick={true}
        draggable={true}
      />
      <SiteInfoProvider initialSiteInfo={pageProps.siteInfo}>
        <CategoryProvider>
          <LimitSavedDesignProvider>
            <SessionProvider session={session}>
              <SubscriptionProvider>
                <AuthProvider>
                  <FilterProvider initialProps={pageProps}>
                    {getLayout(pageProps, <Component {...pageProps} />)}
                  </FilterProvider>
                </AuthProvider>
              </SubscriptionProvider>
            </SessionProvider>
          </LimitSavedDesignProvider>
        </CategoryProvider>
      </SiteInfoProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext) => {
  try {
    // Calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(appContext);

    // Dynamically import jwt and cookie only on the server
    const [jwt, cookie] = await Promise.all([
      import('jsonwebtoken'),
      import('cookie'),
    ]);

    const isServer = typeof window === 'undefined';
    var domain = '';
    // If on server, get domain from request headers
    if (isServer) {
      const token = jwt.sign(
        { count: 50 }, // Payload
        API_SECRET, // Secret key
        { expiresIn: '24h' }, // Expiration time: 5 minutes
      );

      if (appContext.ctx.res) {
        // Set the JWT as a cookie with additional settings
        appContext.ctx.res.setHeader(
          'Set-Cookie',
          cookie.serialize('token', token, {
            httpOnly: true, // Prevent JavaScript access to the cookie
            secure: process.env.NODE_ENV !== 'development', // Use Secure in production (https only)
            maxAge: 24 * 60 * 60, // 24 hours in seconds
            sameSite: 'strict', // Prevent CSRF (strict policy)
            path: '/', // Cookie is accessible on all pages
          }),
        );
      }

      domain = appContext.ctx.req.headers.host;
    } else {
      domain = window.location.hostname;
    }
    if (domain.startsWith('www.')) {
      domain = domain.substring(4);
    }

    const [siteInfo] = await Promise.all([datasource.getSiteInfo(domain)]);

    return { ...appProps, pageProps: { domain, siteInfo } };
  } catch (e) {
    console.error({ e });
  }
};

export default MyApp;
