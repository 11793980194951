import React, { createContext, useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import User from 'services/UserService';

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const { status, data: session } = useSession();

  const [loading, setLoading] = useState(true);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  const checkActiveSubscription = async () => {
    try {
      setLoading(true);
      const data = await User.checkUserSubscriptionStatus();

      if (data.hasActiveSubscription) {
        setActiveSubscription(true);
      }
      if (data.subscriptionEndDate) {
        setSubscriptionEndDate(data.subscriptionEndDate);
      }
      if (data.subscriptionStatus) {
        setSubscriptionStatus(data.subscriptionStatus);
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const handleCheck = async () => {
    if (status === 'unauthenticated') {
      setActiveSubscription(false);
      setSubscriptionEndDate(null);
      setSubscriptionStatus(null);
      setLoading(false);
      return;
    } else if (status === 'authenticated' && session) {
      await checkActiveSubscription();
    } else {
      setActiveSubscription(false);
      setSubscriptionEndDate(null);
      setSubscriptionStatus(null);
    }
  };

  useEffect(() => {
    handleCheck();
  }, [session]);

  return (
    <SubscriptionContext.Provider
      value={{
        activeSubscription,
        subscriptionEndDate,
        subscriptionStatus,
        loading,
        handleCheck,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
