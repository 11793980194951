import { createContext, useContext, useState, useEffect } from 'react';
import SavedDesigns from 'services/SavedDesignsService';

const LimitSavedDesignContext = createContext();

export function LimitSavedDesignProvider({ children }) {
  const [limit, setLimit] = useState('');

  const getLimitAndSetLimit = async () => {
    try {
      // const limitData = 100;// await SavedDesigns.getSavedDesignLimit();
      setLimit('100');
    } catch (e) {
      setLimit('15');
    }
  };

  useEffect(() => {
    getLimitAndSetLimit();
  }, []);

  return (
    <LimitSavedDesignContext.Provider value={{ limit }}>
      {children}
    </LimitSavedDesignContext.Provider>
  );
}

export function useLimitSavedDesignContext() {
  return useContext(LimitSavedDesignContext);
}
